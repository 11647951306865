const preloader = document.getElementById('preloader');
const preloaderWrapper = document.querySelector('.preloader');
const main = document.querySelector('main');
const testimonials = document.querySelector('#testimonial-wrapper');

if ( preloader ) {
    var hash = window.location.hash.substring(1);
    main.style.display = 'none';
    var animation = bodymovin.loadAnimation({
        container: preloader,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path:"/assets/preloader.json"
    });
    window.scrollTo(0,0);
    animation.addEventListener('complete', function() { 
        main.style.display = 'block';
        jQuery('.preloader').delay(300).fadeOut(150);
        jQuery([document.documentElement, document.body]).animate({
            scrollTop: jQuery('#'+hash).offset().top - 100
        }, 1200);
    } );
}

if( testimonials ) {
    var util = UIkit.util;
    var el = util.$('#testimonial-wrapper');

    UIkit.scrollspy(el, { repeat: false });
    
    util.on(el,'inview', function(){
        console.log('test');
        UIkit.slider(
            '#testimonial-slider',
            {
                autoplay:true,
                autoplayInterval: 3500,
            } 
        );
    });
}
